/* global ajax_object */
/* global google */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Swiper slider
import Swiper, { Autoplay, FreeMode, Navigation } from 'swiper';

Swiper.use([Autoplay, FreeMode, Navigation]);

// Import local dependencies

import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/pagination';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Blog post filters
 */
function postsFilters(currentFilter, page, value) {
  $('.posts-wrapper').addClass('loading');

  const data = {
    action: 'action_post_filter',
    nonce: ajax_object.nonce,
    term: currentFilter.data('term'),
    postType: currentFilter.parent().data('post-type'),
    tax: currentFilter.parent().data('tax'),
    paged: page,
    keyword: value,
  };

  $.ajax({
    url: ajax_object.url,
    dataType: 'json',
    type: 'POST',
    data,
  }).done(function (response) {
    $('.posts-wrapper').html(response.html);

    if (page == 1) {
      postsPagination(currentFilter, response.total);
    }

    setTimeout(function () {
      $('.posts-wrapper').removeClass('loading');
    }, 150);
  });
}

/**
 * Pagination
 */
function postsPagination(currentFilter, totalPages) {
  $('#js-pagination').pagination({
    // the number of entries
    total: totalPages,
    // current page
    current: 1,
    // the number of entires per page
    length: 1,
    // pagination size
    size: 2,
    // Prev/Next text
    prev: '<',
    next: '>',

    // fired on each click
    click: function (e) {
      postsFilters(currentFilter, e.current);
    },
  });
}

function smooth_scroll(link, e) {
  let hash;
  if (!link && window.location.hash) {
    hash = window.location.hash;
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    e.preventDefault();
    hash = link.hash;
  }
  if ($(hash).length) {
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top,
      },
      800
    );
    history.replaceState(null, null, hash);
  }
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('.chat-widget__button').click(function () {
    $('.chat-widget__wrapper').slideToggle('slow');
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  const ajaxSearch = $('#keyword');
  $(document).on('click', '.posts-filter-btns__btn', function (e) {
    e.preventDefault();

    const $this = $(this);

    $this
      .closest('.posts-filter-btns')
      .find('.post-btn-active')
      .removeClass('post-btn-active');
    $this.addClass('post-btn-active');

    postsFilters($this, 1, ajaxSearch.val());
  });

  let keyupTimer;
  ajaxSearch.on('keyup', function () {
    const buttonActive = $('.post-btn-active');
    if ($(this).val().length > 3) {
      clearTimeout(keyupTimer);
      keyupTimer = setTimeout(() => {
        postsFilters(buttonActive, 1, $(this).val());
      }, 600);
    } else if ($(this).val().length < 1) {
      postsFilters(buttonActive, 1, $(this).val());
    }
  });
  ajaxSearch.on('keypress', function (e) {
    if (e.which == 13) return false;
  });

  let currentFilter = $('.posts-filter-btns').find('.post-btn-active');
  let totalPages = parseInt($('#js-pagination').attr('data-max-pages'));
  postsPagination(currentFilter, totalPages);

  // Logo slider
  function logoSlider($sliderTwo) {
    let swiperTwo = new Swiper($sliderTwo[0], {
      spaceBetween: 0,
      loop: true,
      effect: 'slide',
      slidesPerView: 1,
      freeMode: true,
      grabCursor: true,
      // allowTouchMove: true,
      // disableOnInteraction: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1024: {
          slidesPerView: 5,
        },
        640: {
          slidesPerView: 3,
        },
        480: {
          slidesPerView: 2,
        },
      },
    });
    swiperTwo.init();
  }

  if ($('.logo-slider').length) {
    $('.logo-slider').each(function () {
      logoSlider($(this));
    });
  }

  // Logo slider 7
  function logoSlider7($sliderTwo) {
    let swiperTwo = new Swiper($sliderTwo[0], {
      spaceBetween: 0,
      loop: true,
      effect: 'slide',
      slidesPerView: 1,
      freeMode: true,
      grabCursor: true,
      // allowTouchMove: true,
      // disableOnInteraction: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1200: {
          slidesPerView: 7,
        },
        1024: {
          slidesPerView: 5,
        },
        640: {
          slidesPerView: 3,
        },
        480: {
          slidesPerView: 2,
        },
      },
    });
    swiperTwo.init();
  }

  if ($('.logo-slider-7').length) {
    $('.logo-slider-7').each(function () {
      logoSlider7($(this));
    });
  }

  // Case study slider
  function caseStudySlider($sliderTwo) {
    let swiperTwo = new Swiper($sliderTwo[0], {
      spaceBetween: 0,
      loop: true,
      effect: 'slide',
      slidesPerView: 1,
      freeMode: true,
      grabCursor: true,
      // allowTouchMove: true,
      // disableOnInteraction: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      // breakpoints: {
      //   320: {
      //     slidesPerView: 1,
      //   },
      // },
    });
    swiperTwo.init();
  }

  if ($('.case-study-slider').length) {
    $('.case-study-slider').each(function () {
      caseStudySlider($(this));
    });
  }

  // Testimonials slider
  function testimonialsSlider($sliderTwo) {
    let swiperTwo = new Swiper($sliderTwo[0], {
      spaceBetween: 80,
      loop: true,
      effect: 'slide',
      slidesPerView: 1,
      freeMode: true,
      grabCursor: true,
      centerMode: true,
      // allowTouchMove: true,
      // disableOnInteraction: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        680: {
          slidesPerView: 2,
        },
      },
    });
    swiperTwo.init();
  }

  if ($('.testimonials-slider').length) {
    $('.testimonials-slider').each(function () {
      testimonialsSlider($(this));
    });
  }

  // Input file upload
  $(function () {
    $('input[type=file]').each(function () {
      let $input = $(this),
        $label = $input.next('.js-labelFile'),
        labelVal = $label.html();

      $input.hide();
      $input.on('change', function (element) {
        let fileName = '';
        if (element.target.value)
          fileName = element.target.value.split('\\').pop();
        fileName
          ? $label.addClass('has-file').find('.js-fileName').html(fileName)
          : $label.removeClass('has-file').html(labelVal);
      });
    });
  });

  //Upload customize
  let labelsWrapper = document.querySelectorAll('.gfield--width-full > div');

  for (let i = 0; i < labelsWrapper.length; i++) {
    if (labelsWrapper[i].classList.contains('ginput_container_fileupload')) {
      labelsWrapper[i].previousSibling.classList.add('label-upload');
    }
  }

  function render_map($el) {
    // var
    var $markers = $el.find('.marker');
    // var styles = []; // Uncomment for map styling

    // vars
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      disableDefaultUI: false,
      // styles : styles // Uncomment for map styling
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  $('.acf-map').each(function () {
    render_map($(this));
  });

  var infowindow;

  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      //icon: $marker.data('marker-icon') //uncomment if you use custom marker
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($.trim($marker.html())) {
      // create info window
      infowindow = new google.maps.InfoWindow();

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {
        // Close previously opened infowindow, fill with new content and open it
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  // let searchForm = $('.search');
  // let searchInput = $('.search__input');
  // let searchIcon = $('.search__submit');
  // searchIcon.attr('disabled', 'disabled');
  //
  // document.addEventListener('click', function (e) {
  //   if (!searchForm.is(e.target) && searchForm.has(e.target).length === 0) {
  //     if (!searchInput.val().length) {
  //       searchIcon.css({ display: 'block' });
  //     }
  //   } else {
  //     searchIcon.css({ display: 'none' });
  //   }
  // });

  smooth_scroll();
  $('a[href*="#"]:not([href="#"],[href="#0"],[href^="#popup-"])').on(
    'click',
    function (e) {
      smooth_scroll(this, e);
    }
  );
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  // searchInput.addEventListener('focus', () => {
  //   searchIcon.style.display = 'none';
  // });

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
